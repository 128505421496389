<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field reservationDate">
            <div class="title">영업일자</div>
            <ul class="content">
              <li class="item dateRange">
                <input-date-range
                  v-model="inputDateRangeValue"
                  type="lookup-condition"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <ul class="content">
              <li class="item input">
                <input-text
                  ref="searchValue"
                  id="searchValueInputTextBox"
                  v-model="searchConditions.searchValue"
                  placeholder="회원명, 캐디명"
                  @keydown.enter="onCaddieArrangementStatementViewClicked"
                />
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              buttonDiv="GET"
              :isShortcutButton="true"
              :ignore="isPopupOpened"
              @click.native="onCaddieArrangementStatementViewClicked"
          >
            조회
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">캐디배치 목록</div>
              <div class="header-caption">[{{ count }}건]</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="add">
                  <erp-button
                      buttonDiv="GET"
                      :isIconCustom="true"
                      @click.native="handleAddVocPopupOpen"
                  >
                    VOC
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                      buttonDiv="FILE"
                      :isIconCustom="true"
                      @click.native="onClickExcel"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body" style="border: none">
            <div class="body-grid">
              <ejs-grid-wrapper
                ref="caddieArrangementStatementListGrid"
                :provides="grid"
                :dataSource="caddieArrangementStatementList"
                :columns="caddieArrangementStatementListGridColumn"
                :selectionSettings="selectionSettings"
                :pageSettings="pageSettings"
                :allowPaging="true"
                :allowResizing="true"
                :allowExcelExport="true"
                @queryCellInfo="queryCellInfo"
                @actionComplete="caddieArrangementStatementListGridActionComplete"
              />
            </div>
          </div>
        </section>
      </article>
    </div>
    <add-voc-popup
      v-if="isAddVocPopupOpen"
      ref="add-voc-popup"
      @popupClosed="handleAddVocPopupClose"
    />
  </div>
</template>

<style scoped>
body .appContent .lookup-condition .field.partDivision .content .item {width: 90px;}
</style>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import commonMixin from "@/views/layout/mixin/commonMixin";
import InputDateRange from "@/components/common/datetime/InputDateRange";
import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import {
  Aggregate,
  Filter,
  Resize,
  Group,
  ExcelExport,
  ForeignKey,
  VirtualScroll,
  Page,
} from "@syncfusion/ej2-vue-grids";

import {
  getDayColorValue,
  getFormattedDate,
} from '@/utils/date';
import {
  commonCodesGetColorValue,
  commonCodesGetComName, commonCodesGetSortNo,
} from '@/utils/commonCodes';
import { numberWithCommas } from "@/utils/number";
import { orderBy as _orderBy } from "lodash";

import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import InputText from "@/components/common/text/InputText";

import GolfErpAPI from "@/api/v2/GolfErpAPI";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import AddVocPopup from '@/views/game-progress/popup/AddVocPopup';

export default {
  name: "CaddieArrangementStatement",
  components: {
    InputText,
    InputDateRange,
    ejsGridWrapper,
    AddVocPopup,
    ErpButton
  },
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  created() {
    this.inputDateRangeValue.from = getFormattedDate(new Date());
    this.inputDateRangeValue.to = getFormattedDate(new Date());
  },
  mounted() {},
  data() {
    return {
      isAddVocPopupOpen: false,
      count: 0,
      caddieArrangementStatementListLength: 0,
      grid: [
        Aggregate,
        Filter,
        Resize,
        Group,
        ExcelExport,
        ForeignKey,
        VirtualScroll,
        Page,
      ],
      filteredFoundIndexList: [],
      savedSearchValue: null,
      selectionSettings: { type: "Single", mode: "Both", enableToggle: false },
      commonCodeFields: { text: "comName", value: "comCode" },
      inputDateRangeValue: { to: null, from: null },
      searchConditions: {
        searchValue: null,
      },
      caddieArrangementStatementList: [],
      caddieArrangementStatementListGridColumn: [
        {
          field: "resveNo",
          visible: false,
          isPrimaryKey: true,
        },
        {
          field: "visitDate",
          headerText: "배치일자",
          textAlign: "center",
          width: 100,
          type: "string",
        },
        {
          field: "dwCode",
          headerText: "요일",
          textAlign: "center",
          isCommonCodeField: true,
          groupCode: "DW_CODE",
          width: 70,
          type: "string",
        },
        {
          field: "bsnCode",
          headerText: "영업구분",
          textAlign: "center",
          isCommonCodeField: true,
          groupCode: "BSN_CODE",
          width: 100,
          type: "string",
        },
        {
          field: "partDiv",
          headerText: "부구분",
          textAlign: "center",
          isCommonCodeField: true,
          groupCode: "PART_DIV",
          width: 80,
          type: "string",
        },
        {
          field: "startTime",
          headerText: "시간",
          textAlign: "center",
          width: 70,
          type: "string",
        },
        {
          field: "startCourse",
          headerText: "코스",
          textAlign: "center",
          isCommonCodeField: true,
          groupCode: "COURSE_CODE",
          width: 80,
          type: "string",
        },
        {
          field: "teamHoleDiv",
          headerText: "홀구분",
          textAlign: "center",
          isCommonCodeField: true,
          groupCode: "HOLE_DIV",
          width: 70,
          type: "string",
        },
        {
          field: "grpName",
          headerText: "단체명",
          width: 120,
          type: "string",
        },
        {
          field: "visitName1",
          headerText: "내장객1",
          width: 100,
          type: "string",
        },
        {
          field: "visitName2",
          headerText: "내장객2",
          width: 100,
          type: "string",
        },
        {
          field: "visitName3",
          headerText: "내장객3",
          width: 100,
          type: "string",
        },
        {
          field: "visitName4",
          headerText: "내장객4",
          width: 100,
          type: "string",
        },
        {
          field: "visitName5",
          headerText: "내장객5",
          width: 100,
          type: "string",
        },
        {
          headerText: "캐디",
          columns: [
            {
              field: "caddieName",
              headerText: "캐디명",
              width: 150,
              type: "string",
            },
            {
              field: "cartNo",
              headerText: "카트번호",
              textAlign: "center",
              width: 150,
              type: "string",
            },
          ],
        },
      ],
      pageSettings: { pageSize: 30 },
    };
  },
  computed: {
    isPopupOpened() {
      return (
        this.isAddVocPopupOpen
      );
    }
  },
  methods: {
    numberWithCommas,
    valueAccessor(field, data, column) {
      let code = data[field];
      let returnCode = null;
      if (code != null) {
        returnCode = commonCodesGetComName(column.comCode, code);
      }
      return returnCode;
    },
    onCaddieArrangementStatementViewClicked() {
      this.getCaddieArrangementStatementList();
    },
    async getCaddieArrangementStatementList() {
      if (!this.inputDateRangeValue.from) {
        return this.errorToast(
          this.$t("main.validationMessage.requiredMessage", ["영업일자"])
        );
      }
      if (!this.inputDateRangeValue.to) {
        return this.errorToast(
          this.$t("main.validationMessage.requiredMessage", ["종료일자"])
        );
      }

      let params = {
        visitDateFrom: this.inputDateRangeValue.from,
        visitDateTo: this.inputDateRangeValue.to,
        name: this.searchConditions.searchValue,
      };

      const data = await GolfErpAPI.fetchCaddieArrangementStatementList(params);

      this.caddieArrangementStatementList = _orderBy(data.map(item => ({
        ...item,
        courseCodeSortNo: commonCodesGetSortNo("COURSE_CODE", item.startCourse),
      })), ["visitDate", "courseCodeSortNo", "startTime"]);
      this.caddieArrangementStatementListLength = data.length;

      this.savedSearchValue = null;
      this.filteredFoundIndexList = [];
    },
    findMemberNameCaddieName: function () {
      const searchValue = this.searchConditions.searchValue;

      if (!searchValue) {
        this.infoToast(this.$t("main.popupMessage.noSearchValue"));
      } else {
        this.findAndSelectRowProcess(searchValue);
      }
    },
    getVisitorFindIndex: function (trimedSearchValue) {
      const filteredCaddieArrangementStatementList = this.caddieArrangementStatementList.filter(
        (item, index) => this.filteredFoundIndexList.indexOf(index) < 0
      );

      const findItem = filteredCaddieArrangementStatementList.find(
        (item) =>
          (item.caddieName && item.caddieName.includes(trimedSearchValue)) ||
          (item.visitName1 && item.visitName1.includes(trimedSearchValue)) ||
          (item.visitName2 && item.visitName2.includes(trimedSearchValue)) ||
          (item.visitName3 && item.visitName3.includes(trimedSearchValue)) ||
          (item.visitName4 && item.visitName4.includes(trimedSearchValue)) ||
          (item.visitName5 && item.visitName5.includes(trimedSearchValue))
      );

      let findIdx = this.caddieArrangementStatementList.findIndex(
        (item) => item === findItem
      );

      if (findIdx === -1 && this.filteredFoundIndexList.length > 0) {
        findIdx = this.filteredFoundIndexList[0];
        this.filteredFoundIndexList = [];
      }

      return findIdx;
    },
    findAndSelectRowProcess: async function (searchValue) {
      if (Array.isArray(this.caddieArrangementStatementList)) {
        if (searchValue) {
          const trimedSearchValue = searchValue.trim();

          if (this.savedSearchValue !== trimedSearchValue) {
            this.savedSearchValue = trimedSearchValue;
            this.filteredFoundIndexList = [];
          }

          const findIdx = this.getVisitorFindIndex(trimedSearchValue);

          if (findIdx === -1) {
            this.errorToast(
              trimedSearchValue + "으로 검색된 캐디배치 정보가 없습니다"
            );
            document.getElementById("searchValueInputTextBox").select();
          } else {
            this.filteredFoundIndexList.push(findIdx);
            await this.caddieArrangementStatementRowSelectProcess(findIdx);
          }
        } else {
          if (
            this.caddieArrangementStatementList.length > 0 &&
            this.caddieArrangementStatementList[0].length > 0
          ) {
            await this.caddieArrangementStatementRowSelectProcess(0);
          }
        }
      }

      /* TODO : 포커싱 안잡히는 오류. GRID에 SELECT되는 시점때문에 input-text로 포커스가 안감.
         TODO : 임시로 setTimeout 사용.
       */
      setTimeout(() => {
        this.$refs.searchValue.focusIn();
      }, 1);
    },
    caddieArrangementStatementRowSelectProcess: function (findIdx) {
      this.$refs.caddieArrangementStatementListGrid.selectRow(findIdx);
    },
    queryCellInfo(args) {
      const {
        cell,
        column: {field},
        data,
      } = args;
      if (field === "dwName") {
        cell.style.color = getDayColorValue(data.dwCode, data.bsnCode);
      } else if (field === "bsnCode") {
        cell.style.color = commonCodesGetColorValue(
          "BSN_CODE",
          data.bsnCode
        );
      } else if (field === "teamHoleDiv") {
        cell.style.backgroundColor = commonCodesGetColorValue("HOLE_DIV", data.teamHoleDiv);
      }
    },
    caddieArrangementStatementListGridActionComplete() {
      this.count = numberWithCommas(
        this.$refs.caddieArrangementStatementListGrid?.getGridBatchCount() || 0
      );
    },
    onClickExcel() {
      this.$refs.caddieArrangementStatementListGrid.excelExport();
    },
    handleAddVocPopupOpen() {
      const rowData = this.$refs.caddieArrangementStatementListGrid.getSelectedRecords();

      if (
        !rowData ||
        !(rowData.length > 0)
      ) {
        this.errorToast("팀을 선택해 주시기 바랍니다.");
        return;
      }

      this.isAddVocPopupOpen = true;

      this.$nextTick(() => {
        this.$refs['add-voc-popup'].showPopup(
          rowData[0].visitDate,
          rowData[0].minArgmtId,
          rowData[0].chkinId,
          rowData[0].startTime,
          rowData[0].startCourse
        );
      });
    },
    handleAddVocPopupClose() {
      this.isAddVocPopupOpen = false;
    },
  },
};
</script>
